@import "../Common/variables";

.media-player {
    height: 300px;
    width: 100%;

    /*  Fix for the small size volume button in the media-player and make all icons of same size  */
    svg {
        min-height: 30px;
        min-width: 30px;
    }
}

.media-table-wrapper {
    text-align: center;
    max-height: 200px;
    // max-width: 48.6%;
    max-width: 97.5%;
    margin-top: 5px;
    margin-left: 15px;
    right: 1px;
    padding: 0px;
    background-color: #343a40;

    .media-table-header {
        font-size: 17.5px;
        font-family: $font_Roboto_Condensed, $font_defaultFont !important;
    }

    .media-table-body {
        font-size: 15.5px;
        font-family: $font_Montserrat, $font_defaultFont !important;

        .media-item,
        .media-download-button-wrapper,
        .media-delete-button-wrapper {
            background-color: #00000000;
            color: #FFFFFF;
            transition: background-color 0.1s ease-in-out;
            transition: color 0.1s ease-in-out;
            cursor: pointer;
        }

        .media-item:hover,
        .media-download-button-wrapper:hover,
        .media-delete-button-wrapper:hover {
            background-color: #293036;
        }

        .media-download-button-wrapper:hover {
            color: #4caf50;
        }

        .media-delete-button-wrapper:hover {
            color: #ba1f33;
        }
    }
}

.media-map-wrapper {
    margin: 0px;
    padding: 0px 13px 0px 0px;
}

/*                  Tablet + Mobile Responsiveness Zone                  */

@media(max-width: 991px) {
    .media-table-wrapper {
        margin: 5px;
        max-width: 100%;
    }

    .media-map-wrapper {
        display: none;
    }

}

/*                  Mobile Responsiveness Zone                  */

@media(max-width: 767px) {
    .media-table-wrapper {
        margin-left: 5px;
    }

}

/*                  4K Responsiveness Zone                  */

@media(min-width: 3800px) {
    .media-table-wrapper {
        margin: 5px;
        max-width: 99.1%;
        margin-left: 15px;
    }

}