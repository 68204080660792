@import "../Common/variables";
@import "../Common/Animations";


.tasks-management-container {
    // font-family: $font_Quicksand, $font_defaultFont;
    min-height: 100%;

    #tasks-container {
        display: flex;
        min-height: 25.6rem;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        overflow: auto;
        max-height: 25rem;
        border: 1px solid #00000020;
        box-shadow: inset 0px -5px 14px -3px #00000020;
        scrollbar-width: thin !important;
        scroll-behavior: smooth;
        border-radius: 3px;

        .task-card-container {
            background-image: linear-gradient(315deg, #212322 0%, #242625F8 80%);
            background-size: 400% 400%;
            background-position: bottom right;
            box-shadow: 1px 2px 5px 1px #00000015;
            border: 1px solid #FFFFFF50;
            color: white;
            padding: 15px 15px;
            border-radius: 2px;
            margin: 5px 5px;
            transition: background-position 500ms ease-in-out;
            width: 49%;
            min-height: 13.5rem;
            height: auto;
            max-height: 17rem;
            overflow: hidden;

            &:hover {
                background-position: top left;
            }

            .task-card {
                position: relative;
                font-family: "QuickSand", sans-serif;
                height: 100%;

                .title {
                    max-width: 32rem;
                    overflow-x: auto;
                    text-overflow: clip;
                    font-family: "MontSerrat", sans-serif;
                    font-weight: 800;
                    white-space: nowrap;
                    scrollbar-width: thin;
                    color: rgb(76, 175, 80);

                    i {
                        font-size: 1.5rem;
                        margin-right: 5px;
                    }
                }

                h4 {
                    font-size: 1.4rem;
                    margin: 0px;

                    &:nth-of-type(2) {
                        margin-top: 5px;
                    }
                }

                .condition-container {
                    font-size: 15px;
                    max-height: 3rem;
                    overflow: auto;
                    scrollbar-width: thin;
                    scrollbar-color: rgb(76, 175, 80) white !important;
                }

                .action-container {
                    font-size: 16px;
                    max-height: 3rem;
                    overflow: auto;
                    scrollbar-width: thin;
                    scrollbar-color: rgb(76, 175, 80) white !important;
                }

                .buttons-container {
                    width: 100%;
                    margin-top: 8px;
                    position: absolute;
                    bottom: 0px;

                    i {
                        float: right;
                        margin: 5px;
                        font-size: 20px;
                        cursor: pointer;
                        transition: color 200ms ease-in-out;

                        &.fa-edit:hover {
                            color: #4caf50;
                        }

                        &.fa-trash-alt:hover {
                            color: rgb(241, 25, 25);
                        }
                    }

                    button {
                        border: 0px;
                        min-height: 2rem;
                    }
                }
            }
        }


    }

    #add-new-task-btn {
        display: block;
        margin-top: 0.6rem;
        text-align: center;
        font-weight: 600;
        bottom: 0px;
        text-decoration: inherit;
        outline: inherit;

        &:hover i {
            // color: rgb(80, 200, 95);
            // color: #2ecc71;
            // color: #27ae60;
            color: #37c2d8;
        }

        i {
            font-size: 2.4rem;
            // color: rgb(76, 175, 80);
            // color: #28a745;
            color: #27b2c8;
            transition: color 200ms ease-in-out;
        }
    }
}

/* Mobile & Tablet Version Compacted Together*/
@media (max-width: 961px) {
    .tasks-management-container {
        #tasks-container {
            .task-card-container {
                width: 100%;
                height: auto;

                .buttons-container {
                    position: unset !important;

                }
            }
        }
    }
}