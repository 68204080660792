@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600|Open+Sans|Roboto|Roboto+Condensed|Quicksand:400,700');

/*    Colors    */
$color_darkGreen: #27ae60;
$color_green: #4CAF50;
$color_lightGreen: #2ecc71;
$color_calmBlue: #2C3E50;
$color_concrete: #95a5a6;
$color_coolGreen: #07d074;
$color_blessedGreen: #0c6f39;
$color_luxuryShadow: #242625;
$color_lessCoolGreen: #07d374;
$color_darkShadow: #212322;
$color_tunedGrey: #2c2e2d;
$color_dyingWhite: #979998;
$color_epicBlue: #2BA18F;
/*    Fonts     */
$font_Roboto_Condensed: 'Roboto Condensed';
$font_Roboto: 'Roboto';
$font_Open_Sans: 'Open Sans';
$font_Montserrat: 'Montserrat';
$font_Quicksand: 'Quicksand';
$font_defaultFont: sans-serif;