@import "../Common/variables";
@import "../Common/Animations";

/* Desktop sizing version */

.loading {
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background: #000000aa;
  position: fixed;
  z-index: 5;

  .fas {
    display: inline-block;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    animation: blink 1s infinite;
    color: $color_concrete;
  }
}

.scheme-control-container {
  font-family: $font_Quicksand, $font_defaultFont;
  /* new style, but needs the page-content to be colored with #ecf0f1*/
  // background: white;
  // padding: 10px 15px;
  // margin-bottom: 5px;
  // border-radius: 5px;// box-shadow: 0px 2px 5px #00000030;

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .form-group {
    margin: 10px 0px;
    flex-basis: 22%;

    &:first-of-type {
      flex-basis: 97%;
      margin-top: 0px;
      margin-bottom: 10px;

      #blueprint-types-container {
        border: none;
        background: none;

        button {
          margin-right: 10px;
          font-size: 15px;
        }
      }
    }
  }

  .table-container {
    max-height: 300px;
    overflow: auto;
    box-shadow: inset 0px -5px 14px -3px #00000010;
    scrollbar-width: thin;

    thead * {
      color: $color_tunedGrey;
    }

    .btn * {
      color: #fffffffa !important;
    }

    tbody * {
      color: $color_calmBlue;
    }
  }

  .error {
    background: crimson;
    color: white;
  }

  .alert {
    margin: 10px 0px;
  }

  .derived-blueprints-select {
    padding: 5px;
    outline: none;
    box-shadow: none;

    &:focus {
      outline: none;
      box-shadow: 0 0 5px 2px #17a2b850;
    }

    &:hover {
      outline: none;
      border-color: rgb(204, 204, 204);
    }

    .react-dropdown-select-option {
      padding: 5px 8px;
      background: #17a2b8;
    }

    .react-dropdown-select-item-selected {
      background: #17a2b8;
      border-bottom: 1px solid #ffffffa0;
    }
  }
}

/* Mobile & Tablet Version Compacted Together*/
@media (max-width: 961px) {
  .scheme-control-container {
    .form-group {
      margin: 10px 10px;
      flex-basis: 100%;

      &:first-of-type {
        #blueprint-types-container {
          height: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;

          button {
            min-height: 2.8rem;
            margin: 2px;
            width: 45%;
          }
        }
      }
    }
  }
}
