@import "../Common/animations";

:root {
  --default-trigger-background-color: #202020;
  --conditional-trigger-background-color: #107a8b;
  --chronical-trigger-background-color: #ff5959;
  --disabled-trigger-background-color: #505050;
}

#add-task-container {
  display: flex;
  flex-direction: column;

  #triggers-list-placeholder {
    text-align: center;
    vertical-align: middle;
    padding-top: 6.2rem;
    height: 15rem;
    font-family: "Montserrat", sans-serif !important;
    color: #c0c0c0;
  }

  .subsection-title {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400;
    font-size: 22px;
    color: #242625e0;
  }

  ul {
    list-style: none;
    padding: 0px;
    border: 1px solid #20202025;
    border-radius: 2px;
    height: 15.6rem;
    overflow: auto;

    li {
      position: relative;
      background: var(--default-trigger-background-color);
      border-radius: 0px 2px 2px 2px;
      transition: background-position 500ms ease-in-out;
      padding: 15px 10px;
      margin: 5px;
      margin-top: 2.5rem; //Margin top to avoid the task-type-container overlapse

      &.trigger-mounted {
        animation: fade-in-transition 100ms ease-in-out forwards;
      }

      &[type="conditional"] {
        background: var(--conditional-trigger-background-color);

        .trigger-type-container {
          background: var(--conditional-trigger-background-color);
        }
      }

      &[type="chronical"] {
        background: var(--chronical-trigger-background-color);

        .trigger-type-container {
          background: var(--chronical-trigger-background-color);
        }
      }

      .trigger-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0px !important;
        padding-right: 10px;
      }

      .trigger-type-container {
        position: absolute;
        background: #202020;
        color: white;
        top: -28px;
        left: 0px;
        padding: 2px 15px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-size: 22px;
      }

      span {
        margin: 5px;

        input[type="text"] {
          display: inline-block;
          font-family: "Montserrat", sans-serif;
          width: 15rem;
          border-radius: 2px;
        }
      }

      .delete-trigger-container {
        margin: 5px 0px 5px auto !important;
        text-align: center;
        cursor: pointer;
        color: white;

        i {
          font-size: 1.5rem;
          transition: color 200ms ease-in-out;

          &:hover {
            color: #f72323;
          }
        }
      }

      .dropdown-select-container {
        z-index: 10 !important;

        & > div {
          display: inline-block;
          min-width: 15rem;
          background: white;
          border-radius: 2px;

          .operators {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            // font-size: 18px;
          }

          .react-dropdown-select {
            border-radius: 2px;

            input {
              margin: 2.5px 0px;
              // padding: 0.375rem 0.75rem;
              font-family: "Montserrat", sans-serif;
              font-size: 16px;
              font-weight: 500;
            }

            .react-dropdown-select-dropdown {
              width: 101%;
              max-height: 8rem;
              span {
                background: inherit !important;
                color: inherit !important;
              }
            }
          }
        }
      }
    }
  }

  .add-trigger-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    height: 5rem;
    border-radius: 4px;
    // background: #e0e0e0;
    background: #f9f6f6;
    border: 1px solid #00000010;
    padding: 5px 0px;

    button {
      height: 3rem;
      width: 20rem;
      border: 0px;
      color: white;
      border-radius: 2px;
      transition: all 300ms ease-in-out;
      background: #ffffff80;
      cursor: pointer;
      margin: 0px 20px;
      // background: #e0e0e0;

      &[type="conditional"] {
        border: 2px solid var(--conditional-trigger-background-color);
        color: var(--conditional-trigger-background-color);

        &:hover {
          background: var(--conditional-trigger-background-color);
          color: white;
        }
      }

      &[type="chronical"] {
        border: 2px solid var(--chronical-trigger-background-color);
        color: var(--chronical-trigger-background-color);

        &:hover {
          background: var(--chronical-trigger-background-color);
          color: white;
        }
      }

      &:disabled {
        border: 2px solid var(--disabled-trigger-background-color);
        color: var(--disabled-trigger-background-color);
        cursor: not-allowed;

        &:hover {
          background: var(--disabled-trigger-background-color);
          color: #ffffff40;
        }
      }

      i {
        font-size: 22px;
      }

      span {
        font-family: "Quicksand", sans-serif !important;
        font-size: 22px;
        padding: 0px 10px;
      }
    }
  }

  #interpreter-container {
    width: 100%;
    min-height: 5rem;
    max-height: 6rem;
    overflow: auto;
    margin: 10px 0px;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 1.4rem;
    background: #f9f6f6;
    border: 1px solid #c0c0c0;
    scrollbar-width: thin;
    margin-bottom: 1.2rem;

    #interpretation {
      color: #212322e0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
  }

  #actions-container {
    margin-top: 0.5rem;

    .react-dropdown-select-option {
      padding: 5px 10px !important;
    }
  }

  #create-task {
    font-family: "Montserrat", sans-serif;
    // font-weight: 600;
    text-align: center;
    margin: 0px auto;
    width: fit-content !important;
    padding: 0.5rem 1rem;
    font-size: 18px;
    // margin: 0px rem;
  }
}

/* Mobile & Tablet Version Compacted Together*/
@media (max-width: 961px) {
  .add-trigger-container {
    height: auto !important;
    margin-bottom: 15px !important;

    i {
      width: 100%;
    }

    button {
      padding: 5px;
      min-height: 3rem;
      height: auto !important;
      width: auto !important;
      margin: 10px 20px !important;
    }
  }
}
