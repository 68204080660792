@import "../Common/variables";

body {
    background-color: #FFFFFF !important;
}

.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #212529;
    color: #979998;
    padding: 20px 30px;
    width: 40%;
    border-radius: 3px;
}

.input-block {
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
    flex-basis: 100%;

    label {
        margin-right: 10px;
        font-family: $font_Montserrat, $font_defaultFont;

    }

    input {
        padding-left: 10px;
    }
}

.forgot-password {
    flex-basis: 100%;
    font-family: $font_Quicksand, $font_defaultFont;
    font-size: 14px;
    margin-top: 5px;
    margin-right: auto;
}

.register {
    flex-basis: 100%;
    font-family: $font_Quicksand, $font_defaultFont;
    font-size: 16px;
    margin-top: 0px;
    margin-right: auto;

    a {
        text-decoration: inherit;
        transition: color 300ms ease-in-out;
        color: #17a2b8;

        &:hover {
            color: #138496;
        }
    }
}

.login-button {
    flex-basis: 100%;
    font-family: $font_Quicksand, $font_defaultFont;
    font-weight: "500";
    margin: 30px 0px 10px 0px;
}

.register-button {
    flex-basis: 100%;
    font-family: $font_Quicksand, $font_defaultFont;
    font-weight: "500";
    margin: 30px 0px 10px 0px;
}

a.button-text {
    flex-basis: 100%;
    font-family: $font_Quicksand, $font_defaultFont;
    font-weight: "500";
    margin: 0px 0px 10px 0px;
    text-decoration: inherit;

    .cancel-button {
        width: 100%;
        color: #FFFFFF;
    }

}

.logo,
.logo:hover {
    text-decoration: none;
    font-size: 25px;
    color: #4caf50;
    font-family: $font_Montserrat, $font_defaultFont;
}

.alert {
    margin-top: 10px;
    margin-bottom: -10px;
}

/*                  Mobile Responsiveness Zone                  */

@media(max-width: 767px) {
    .form-container {
        width: 100%;

        .register {
            width: 100%;
            text-align: center;
        }
    }
}