.font-color-1 {
  color: $color_darkGreen !important;
}

.font-color-2 {
  color: $color_green !important;
}

.font-color-3 {
  color: $color_lightGreen !important;
}

.font-color-4 {
  color: $color_coolGreen !important;
}

.font-color-5 {
  color: $color_blessedGreen !important;
}

.font-color-6 {
  color: $color_calmBlue !important;
}

.font-color-7 {
  color: $color_concrete !important;
}

.font-color-8 {
  color: $color_luxuryShadow !important;
}

.font-color-9 {
  color: $color_lessCoolGreen !important;
}

.font-color-10 {
  color: $color_darkShadow !important;
}

.font-color-11 {
  color: $color_tunedGrey !important;
}

.font-color-12 {
  color: $color_dyingWhite !important;
}

.font-color-13 {
  color: $color_epicBlue !important;
}

.background-color-1 {
  background-color: $color_darkGreen !important;
}

.background-color-2 {
  background-color: $color_green !important;
}

.background-color-3 {
  background-color: $color_lightGreen !important;
}

.background-color-4 {
  background-color: $color_coolGreen !important;
}

.background-color-5 {
  background-color: $color_blessedGreen !important;
}

.background-color-6 {
  background-color: $color_calmBlue !important;
}

.background-color-7 {
  background-color: $color_concrete !important;
}

.background-color-8 {
  background-color: $color_luxuryShadow !important;
}

.background-color-9 {
  background-color: $color_lessCoolGreen !important;
}

.background-color-10 {
  background-color: $color_darkShadow !important;
}

.background-color-11 {
  background-color: $color_tunedGrey !important;
}

.background-color-12 {
  background-color: $color_dyingWhite !important;
}

.background-color-13 {
  background-color: $color_epicBlue !important;
}

.font-type-1 {
  font-family: $font_Roboto_Condensed !important;
}

.font-type-2 {
  font-family: $font_Roboto !important;
}

.font-type-3 {
  font-family: $font_Open_Sans !important;
}

.font-type-4 {
  font-family: $font_Montserrat !important;
}

.font-type-5 {
  font-family: $font_Quicksand !important;
}

.font-size-1 {
  font-size: 15px !important;
}

.font-size-2 {
  font-size: 20px !important;
}

.font-size-3 {
  font-size: 25px !important;
}

.font-size-4 {
  font-size: 30px !important;
}

.font-size-5 {
  font-size: 35px !important;
}

.font-size-6 {
  font-size: 40px !important;
}

.font-size-7 {
  font-size: 45px !important;
}

.font-size-8 {
  font-size: 50px !important;
}

.font-size-9 {
  font-size: 55px !important;
}

.font-size-10 {
  font-size: 60px !important;
}

.font-size-11 {
  font-size: 65px !important;
}

.font-size-12 {
  font-size: 70px !important;
}

.font-size-13 {
  font-size: 75px !important;
}

.font-size-14 {
  font-size: 80px !important;
}

.font-size-15 {
  font-size: 85px !important;
}

.font-size-16 {
  font-size: 90px !important;
}

.early-hidden {
  display: none !important;
}