@import "variables";
@import "Animations";
@import "utils";

#collapse-button {
  margin-top: 20px;
  width: 100%;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.2) !important;
  color: $color_dyingWhite;
  display: none;
}

#collapse-button:hover {
  color: $color_concrete !important;
  background: $color_tunedGrey !important;
}

#section-title {
  h2 {
    font-family: $font_Quicksand, $font_defaultFont;
  }
}

.status-icon {
  color: #07d074;
}

.status-bar {
  text-align: center;
  list-style-type: none;
  margin-top: 5px;
  margin-left: 100px;
  padding: 0;

  li {
    display: inline;
    padding-right: 10px;

    span {
      color: $color_dyingWhite;
      font-size: 15px;
      font-family: $font_Montserrat, $font_defaultFont !important;
      padding: 0px 5px;
    }
  }
}

.user-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  color: $color_dyingWhite;
  padding-right: 2px;

  .username {
    margin-right: 10px;
    max-width: 160px;
    overflow: hidden;
  }
}

.control-bar {
  padding: 0px;
}

.data-bar {
  width: 200px !important;
  max-height: 200px !important;
  overflow: hidden !important;
  background-color: white;
  flex-basis: 2%;
}

.search-bar {
  display: inline;
  margin-left: 20px;
}

.data-bar-list {
  display: inline;
}

#side-bar {
  background: $color_darkShadow;
  padding: 0;
  overflow: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  position: relative;

  #logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    transition: 500ms ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    img {
      height: auto;
      width: 45px;
      flex: 1;
    }

    a {
      text-decoration: none;
      text-align: center;
      margin-top: 5px;
      min-width: 100%;
      font-size: 16px;
      color: $color_green;
      font-family: $font_Montserrat, $font_defaultFont;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  ul {
    padding: 0;
    margin-top: 50px;
    list-style: none;
  }

  a {
    outline: none !important;
    text-decoration: none !important;
  }

  li {
    padding: 10px 0;
    margin: 1px 0;
    transition: border 0.08s ease-in-out;
    display: flex;
    justify-content: center;
    /* This empty border is required for the transition to be smooth back and forth*/
    border: 0px black solid;

    span {
      display: block;
      width: 100%;
      height: 100%;
      padding-left: 10px;
      color: $color_dyingWhite;
      color: #ddddddd0;
      font-family: $font_Quicksand, $font_defaultFont;
      font-size: 15px;
    }
  }

  li:hover {
    border-left: 5px $color_blessedGreen solid !important;
    background: $color_tunedGrey;
    border-radius: 1px;
    outline: none;
  }

  li[data-active="true"] {
    background-color: #ecf0f120;
    border: 1px #00000010 solid !important;
  }

  li:hover,
  li[data-active="true"] {
    span {
      color: white !important;
    }
  }

  .fixed-sidebar-footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    width: 100%;

    hr {
      background: white;
      margin: 10px 15px;
      opacity: 0.05;
    }

    h4 {
      font-size: 12px;
      font-family: $font_Roboto, $font_defaultFont;
      color: white;
      opacity: 0.1;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.right-slide-in {
  animation: right-slide-in 5s ease-in-out infinite;
}

#user-info {
  float: right;
}

@for $i from 1 through 15 {
  #side-bar ul li:nth-child(#{$i}) {
    animation: slide-in calc(200ms * #{$i}) ease-in-out !important;
  }
}

.slide-in {
  animation: slide-in 1s ease-in-out !important;
}

#side-bar a.active {
  color: $color_blessedGreen;
}

.dashboard-header {
  vertical-align: middle;
  padding: 15px 0;
  border-bottom: 1px #bdc3c7 solid;
  background: #242625;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style: none;
    li {
      padding-left: 15px;
      color: #676a6c;
    }
  }

  a {
    color: #979998;
    transition: color 0.2s;
    text-decoration: none !important;
    &:hover {
      color: #07d074;
      transition: color 0.4s;
      text-decoration: none !important;
    }
  }

  input {
    font-family: "Roboto Condensed", sans-serif;
    background: #2c2e2d !important;
    color: #979998 !important;
    border: 0 !important;
    width: 300px !important;
    font-size: 14px !important;
    height: 30px !important;
  }
}

#page-content {
  // scrollbar-color: #212322E0 #DDDDDDB0;
  scrollbar-color: #212322e0 transparent;
  scroll-behavior: smooth;
}

#page-content .user-info .username {
  opacity: 0.4;
}

.form-control:focus {
  box-shadow: none !important;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px rgba(189, 195, 199, 0.35) solid;
  color: $color_dyingWhite;
  padding: 10px;
  font-size: 13px;
}

footer .social-media-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  color: $color_blessedGreen !important;
  margin: 0px 1px;
  transition: color 0.2s;
}

footer .social-media-icon:hover {
  color: $color_coolGreen !important;
  transition: color 0.2s;
}

.box {
  border: 2px #3498db solid;
}

.dashboard-content-pane {
  margin-top: 10px;
  width: auto;
  padding: 0 15px;
  color: #212322;
  overflow: auto;
  max-height: 81%;
  height: 100%;
  scrollbar-width: thin;
}

.sm-scrollable {
  overflow: visible !important;
}

.uc-notification-card {
  vertical-align: middle;
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin-top: 220px;
  margin-bottom: 220px;
}

.info-card {
  background: #ecf0f1;
  border: 1px solid #bdc3c7;
  height: auto;
  margin-top: 10px;
  border-radius: 2px;
  transition: all 0.3s;
}

.info-card:hover {
  opacity: 0.75;
}

.full-opacity-info-card:hover {
  opacity: 1 !important;
}

.info-card-icon {
  vertical-align: middle;
  text-align: center;
  padding-top: 5px;
  font-family: $font_Montserrat, $font_defaultFont;
  color: $color_epicBlue;
  font-size: 20px;
}

.info-card-title {
  vertical-align: middle;
  text-align: center;
  height: 50px;
  font-family: $font_Montserrat, $font_defaultFont;
  color: $color_epicBlue;
  font-size: 20px;
}

.info-card-value {
  vertical-align: middle;
  text-align: center;
  margin-top: -25px;
  font-family: $font_Roboto_Condensed, $font_defaultFont;
  color: #27ae60;
  font-size: 20px;
}

.videoArticle {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin-bottom: 10px;
}

.videoInfo {
  flex-shrink: 5;
}

.videoRender video {
  width: 500px;
  margin-right: 10px;
}

.videoTitle {
  font-family: $font_Roboto_Condensed, $font_defaultFont;
  color: $color_epicBlue;
  font-size: 30px;
}

.videoDescription {
  font-family: $font_Montserrat, $font_defaultFont;
  padding-right: 20px;
  color: #27ae60;

  button {
    margin-left: 20px;
  }
}

#content-fragments img {
  max-height: 520px !important;
  max-width: 550px;
}

.blink {
  animation: blink 1s linear infinite;
}

.panel-container {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

#tableRecords {
  & > tr > * {
    font-family: $font_Roboto;
    font-weight: 100;
  }
}

.table-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #4caf50;
    font-family: $font_Montserrat, $font_defaultFont;
  }
}

.scrollable-table {
  height: 325px;
  margin: 10px 15px 10px 10px;
  overflow-y: auto;
  position: relative;
  background-color: #343a40;

  table {
    margin: 0px !important;
  }

  thead {
    position: sticky;
    top: 0%;
    left: 0%;
    right: 0%;
    background-color: #212529;
  }

  h1 {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;
    background-color: #454d55;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
  }
}

#variable-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;

  * {
    color: $color_epicBlue;
  }

  label {
    margin-left: 10px;
    cursor: pointer;
  }

  span {
    color: #eeeeee !important;
  }

  input {
    background-color: black;
    position: "absolute";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #ff00ff00;
    display: none;
  }
}

.records-pane {
  min-width: 98%;
}

.record-container {
  margin: 0px 15px 0px 15px;
  flex-basis: 30%;
  flex-grow: 4;
  flex-shrink: 1;
  flex-direction: column;
  flex-wrap: wrap;
}

.tooltip-sensor {
  $background-color: #28a745;
  .arrow::before {
    border-top-color: $background-color;
  }
  .tooltip-inner {
    background: $background-color;
  }
}

.tooltip-action {
  $background-color: #ffc107;
  $text-color: #212529;
  .arrow::before {
    border-top-color: $background-color;
  }
  .tooltip-inner {
    color: $text-color;
    background: $background-color;
  }
}

.btn-success-light {
  $background-color: #28a745;
  background-color: $background-color !important;
  border-color: $background-color !important;
  box-shadow: none !important;
}

.btn-warning-light {
  $background-color: #ffc107;
  background-color: $background-color !important;
  border-color: $background-color !important;
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .display-table-cell {
    display: block;
  }

  .status-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .status-bar i {
    margin: 0px 5px 0px 5px;
  }

  .status-bar li {
    display: block;
    text-align: center;
    padding-right: 0px;
  }

  .status-bar li span {
    padding-right: 0px;
    padding-left: 0px;
  }

  .control-bar {
    display: block;
  }

  .user-info {
    display: block;

    .username {
      margin-right: 0px;
      max-width: 100%;
    }
  }

  .data-bar {
    flex-basis: 1;
    flex-wrap: wrap;
  }

  .status-bar {
    flex-basis: 1;
    margin-left: auto;
    margin-right: 10px;
    flex-wrap: wrap;
  }

  .search-bar {
    flex-wrap: wrap;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px !important;
    height: 30px !important;
    min-width: 95%;
  }

  #user-info {
    flex-basis: 1;
    color: $color_dyingWhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #social-media-icons-set {
    margin-left: 0 !important;
  }

  #side-bar {
    height: auto;

    ul {
      margin: 0;
      padding-left: 0;
    }

    .fixed-sidebar-footer {
      display: none;
    }
  }

  footer {
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    text-align: center;
    vertical-align: middle;
  }

  #social-media-icons-set {
    flex-basis: 100%;
  }

  #collapse-button {
    display: block !important;
  }

  .videoArticle {
    display: block;
  }

  .videoRender {
    display: block;
  }
}

/*      Mobile Size      */
@media (max-width: 767px) {
  .container-fluid {
    min-height: 100vh;
  }

  .scrollable-table > h1 {
    font-size: 1.6em;
  }

  .DateRangePicker {
    margin-bottom: 5px;

    .DateRangePickerInput {
      width: 100%;
    }
  }

  #variable-selection {
    flex-direction: row;
    flex-wrap: wrap;

    label {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  #side-bar {
    height: auto;
  }

  .sm-fixed-bottom {
    bottom: 0;
    position: absolute;
  }

  .sm-scrollable {
    overflow-y: scroll !important;
  }

  #side-bar ul {
    margin-top: 10px;
  }

  #collapse-button {
    display: block !important;
  }

  .status-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .status-bar i {
    margin: 0px 5px 0px 5px;
  }

  .status-bar li {
    display: block;
    text-align: center;
    padding-right: 0px;
  }

  .status-bar li span {
    padding-right: 0px;
    padding-left: 0px;
  }

  .control-bar {
    display: block;
  }

  .user-info {
    display: block;

    .username {
      margin-right: 0px;
      max-width: 100%;
    }
  }

  .data-bar {
    flex-basis: 1;
    flex-wrap: wrap;
  }

  .status-bar {
    flex-basis: 1;
    margin-left: auto;
    margin-right: 10px;
    flex-wrap: wrap;
  }

  .search-bar {
    flex-wrap: wrap;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px !important;
    height: 30px !important;
    min-width: 90%;
  }

  .dashboard-header {
    display: flex;
    margin-top: -5px;
    padding-bottom: 20px;
    align-items: center;
  }

  footer {
    display: flex;
    width: 100vw;
    min-width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .videoArticle {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .info-card-value {
    /*Data & Time selection Mobile*/
    div {
      flex-basis: 100%;
      flex-grow: 1;
    }

    input {
      flex-basis: 100%;
      flex-grow: 2;
      margin: 0 0 5px 0 !important;
    }

    button {
      flex-basis: 100% !important;
      flex-grow: 1;
      margin: 0 0 5px 0 !important;
    }
  }
}

/*                  Tablet + Mobile Responsiveness Zone                  */

@media (max-width: 991px) {
  #content-container {
    min-height: 29.5rem;
  }

  .graphContainer {
    min-width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: auto;
    left: -10px;

    canvas {
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  #variable-selection {
    min-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px !important;
  }

  .dashboard-content-pane {
    margin-bottom: 20px;
    max-height: 100%;
    height: auto;
  }

  .records-pane {
    position: static;
    min-width: 100%;
    transform: translate(0, 0);
    top: 0%;
    left: 0%;
  }

  .record-container {
    margin: 0px 15px 0px 15px;
    flex-basis: 100%;
    flex-grow: 4;
    flex-shrink: 1;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@media (min-width: 1024px) {
  .collapse {
    display: block !important;
  }

  .display-table {
    display: inline-block;
    height: 100vh;
  }

  .display-table-row {
    height: 100vh;
  }

  .display-table-cell {
    display: table-cell;
    height: 100vh;
  }

  .data-bar-list {
    margin-left: 10px !important;
  }
}

@media (max-width: 1024px) {
  #social-media-icons-set {
    margin-left: -25px !important;
    min-width: 150px !important;
  }

  .videoRender video {
    display: block;
    width: 100%;
  }

  .status-bar {
    margin-left: 0px;
  }
}
