@import "../Common/variables";
@import "../Common/common";
@import "../Common/utils";

.container-fluid {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
}

.animated-body {
  background: url($HOME_BACKGROUND_IMG) no-repeat center center fixed !important;
  background-size: cover !important;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.login-body {
  background-color: #212322 !important;
  background-image: url($LOGIN_BACKGROUND_SVG) !important;
  // background-size: contain !important;
  background-position: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition-duration: 300ms;

  &:hover {
    opacity: 0.8;
  }

  img {
    height: auto;
    width: 46px;
    margin: 0px 10px 0px 5px;
  }

  a {
    text-decoration: none;
    font-size: 20px !important;
    color: $color_green !important;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $font_Montserrat, $font_defaultFont !important;
  }
}

.home-header {
  vertical-align: middle;
  padding: 15px 0px;
  background: $color_darkShadow;
  text-align: center;

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    list-style: none;

    li {
      float: left;
      vertical-align: center;
      padding-left: 15px;
      color: #676a6c;

      a {
        color: $color_dyingWhite;
        transition: color 0.2s;
        text-decoration: none !important;

        &:hover {
          color: $color_coolGreen;
          transition: color 0.4s;
          text-decoration: none !important;
        }
      }
    }
  }
}

.page-category-container {
  width: 100vw;
  background: #000000a0;
  margin-left: -15px;
  display: flex;

  .page-category {
    display: inline-block;
    color: grey;
    font-family: MontSerrat, sans-serif;
    cursor: pointer;
    padding: 5px;
    margin-left: 15px;
    transition-duration: 250ms;
    border-bottom: 1px solid #ffffff00;
  }

  .page-category:hover {
    color: white;
  }

  .page-category.active {
    color: white;
    border-bottom: 1px solid #ffffff;
  }
}

.home-content-pane {
  // height: 75vh;
  height: 100%;
  padding-bottom: 50px;
  justify-content: center;
  align-content: center;
  width: auto;
}

.cover-container {
  margin: 0px 15px 0px 15px;
  flex-basis: 20%;
  flex-grow: 4;
  flex-shrink: 1;
  flex-direction: column;
  flex-wrap: wrap;
  height: 16vh;
  margin-top: 10px;
  margin-bottom: 10px;

  .cover-card {
    background: rgba(0, 0, 0, 0.6);
    border: 1px $color_coolGreen solid;
    height: 100%;
    border-radius: 2px;
    transition: background 0.3s;

    &:hover {
      background: $color_luxuryShadow;
      transition: background 0.3s;
    }

    .cover-card-title {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color_dyingWhite;
      font-family: $font_Quicksand, $font_defaultFont;
      text-align: center;
      font-size: 17px;

      i {
        font-size: 18px;
        padding-right: 2px;
        color: $color_coolGreen;
      }

      a {
        text-decoration: none !important;
        color: $color_dyingWhite;
        transition: color 0.2s;

        &:hover {
          text-decoration: none !important;
          transition: color 0.3s;
          color: $color_coolGreen;
        }
      }
    }
  }

  .ucp-cover-card {
    border: 1px #eb2424 solid !important;

    a:hover {
      color: #eb2424 !important;
    }

    i {
      color: #eb2424 !important;
    }
  }
}

.home-footer {
  background: rgba(1, 1, 1, 0.3);
  width: 100%;
  border-top: 1px rgba(189, 195, 199, 0.35) solid;
  color: $color_dyingWhite;
  padding: 10px;
  padding-right: 0px;
  font-size: 13px;

  a {
    margin: 0px 1px;

    .social-media-icon {
      width: 25px;
      height: 25px;
      margin: auto;
      display: inline-block;
      color: #2ba18f !important;
      transition: color 0.2s;

      &:hover {
        color: #55b09f !important;
        transition: color 0.2s;
      }
    }
  }
}

@media (max-width: 767px) {
  .animated-body {
    background: url($HOME_BACKGROUND_IMG) no-repeat center center !important;
    background-size: cover !important;
  }

  .login-body {
    background-color: #212322 !important;
    background-image: url($LOGIN_BACKGROUND_SVG) !important;
  }

  .home-header ul {
    padding: 10px 0px;
  }

  .home-content-pane {
    height: auto;
    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .container-fluid {
    height: 100% !important;
  }

  #logo {
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      width: 100%;
    }
  }

  .home-content-pane {
    min-width: 100%;
    height: auto;
    flex-direction: column;
    padding: 0px 20px;

    .cover-container {
      margin: 10px 0px;
      flex-basis: auto;
      width: 100%;
    }
  }

  .home-footer {
    margin-top: auto;
    text-align: center;
  }
}
