@keyframes blink {
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes random-colors {
  0% {
    background-color: #27ae60;
  }

  10% {
    background-color: #4caf50;
  }

  20% {
    background-color: #2ecc71;
  }

  30% {
    background-color: #2c3e50;
  }

  40% {
    background-color: #95a5a6;
  }

  50% {
    background-color: #07d074;
  }

  60% {
    background-color: #0c6f39;
  }

  70% {
    background-color: #242625;
  }

  80% {
    background-color: #07d374;
  }

  90% {
    background-color: #212322;
  }

  100% {
    background-color: #2c2e2d;
  }
}

@keyframes fade-in-transition {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }
}
