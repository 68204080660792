.attached-buttons-group-container {
    margin: 10px 8px;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    --side-border-color: #169bb0;

    .left-attached-button {
        padding: 7px 0px 9px 0px;
        border-radius: 5px 0px 0px 5px;
        border-right: 1px solid var(--side-border-color);
        flex-grow: 8;
    }

    .right-attached-button {
        padding: 7px 0px 9px 0px;
        border-radius: 0px 5px 5px 0px;
        flex-grow: 2;
    }

    .mid-attached-button {
        padding: 7px 0px 9px 0px;
        border-radius: 0px;
        border-right: 1px solid var(--side-border-color);
        flex-grow: 2;
    }

    .attached-button-icon {
        margin-right: 5px;
    }

}


@media (max-width: 767px) {
    .attached-buttons-group-container {

        .left-attached-button {
            flex-grow: 1 !important;
        }

        .right-attached-button {
            flex-grow: 1 !important;
        }

        .mid-attached-button {
            flex-grow: 1 !important;
        }

        .attached-button-icon {
            display: block;
        }
    }
}