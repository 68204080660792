@import "./variables";

.icons-dictionary {
  flex-basis: 97%;

  #icon-select {
    display: grid;
    grid-template-columns: repeat(20, 50px);
    grid-template-rows: repeat(60, 30px);
    overflow: auto;
    min-height: 105px;
    scrollbar-width: thin;

    .icon-group {
      display: relative;
      max-width: 20px;
      padding: 5px 15px;

      label {
        justify-self: center;
        align-self: center;
        cursor: pointer;
        z-index: 1;
        transform: translateY(-25px);
      }

      input[type="radio"] {
        position: static;
        visibility: hidden;
      }

      input[type="radio"]:checked + label {
        color: $color_coolGreen;
      }
    }
  }

  .fa,
  .fab,
  .far,
  .fas {
    color: $color_tunedGrey;
  }
}

/* Mobile & Tablet Version Compacted Together*/
@media (max-width: 961px) {
  .icons-dictionary {
    max-width: 97%;
  }
}
