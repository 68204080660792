.dateAndTimePickerContainer {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
}

.dateRangePicker {
    flex-basis: 30%;
    width: 100%;
}

.timeInputField {
    flex-basis: 34%;
    margin: 0px 5px 5px 5px;
    padding: 23px 15px;
}

.chartjs-render-monitor {
    min-height: 100px !important;
    max-height: 400px !important;
    max-width: 100% !important;
}

/*                  Mobile Responsiveness Zone                  */
@media(max-width: 767px) {
    .dateAndTimePickerContainer {
        flex-wrap: wrap;
    }

    .dateRangePicker {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 5px;
    }

    .timeInputField {
        flex-basis: 100%;
        margin: 0px 5px 5px 5px;
        padding: 23px 15px;
    }

    /* ClassName has to be chartjs-render-monitor as it is the replaced class*/
    .chartjs-render-monitor {
        min-height: 100px !important;
        max-height: 200px !important;
        max-width: 100% !important;
    }
}

/*                  Tablet + Mobile Responsiveness Zone                  */

@media(max-width: 991px) {
    .dateRangePicker {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 50px;
    }

    .timeInputField {
        flex-basis: 10%;
    }

    .dateAndTimePickerContainer {
        margin-bottom: 10px;
    }
}